import React from "react";
import { Menu } from "./header/style";
import { NavLink } from "react-router-dom";

export const MenuHeader = () => (
  <Menu style={{ marginTop: "20px" }}>
    <NavLink
      style={{ color: "#ED5152" }}
      exact
      activeStyle={{
        fontWeight: "bold",
        color: "#ED5152",
      }}
      to="/"
    >
      __inicio__
    </NavLink>
    {"  /  "}
    <NavLink
      style={{ color: "#ED5152" }}
      exact
      activeStyle={{
        fontWeight: "bold",
        color: "#ED5152",
      }}
      to="/about"
    >
      __about__
    </NavLink>
  </Menu>
);
