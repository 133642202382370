import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import Text from "./Text";
import _isMobile from "../utils/mobile";

export default function TextJumbo() {
  const ref = useRef();
  useFrame(
    ({ clock }) =>
      (ref.current.rotation.x =
        ref.current.rotation.y =
        ref.current.rotation.z =
          Math.sin(clock.getElapsedTime()) * 0.2)
  );

  return (
    <group ref={ref} rotation-x={0.1}>
      [
      <Text
        hAlign="left"
        position={_isMobile() ? [2, 3.5, 0] : [-2, 2, 0]}
        children="LEONARDO"
      />
      <Text
        hAlign="left"
        position={_isMobile() ? [2, 3, 0] : [-2, 1.5, 0]}
        children="VELARDE"
      />
      <Text
        hAlign="left"
        position={_isMobile() ? [2, 2.5, 0] : [-2, 1, 0]}
        size={0.05}
        children="SOFTWARE"
      />
      <Text
        hAlign="left"
        position={_isMobile() ? [2, 2.2, 0] : [-2, 0.7, 0]}
        size={0.05}
        children="ENGINEER"
      />
    </group>
  );
}
