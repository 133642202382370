import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useDrag, useHover } from "@use-gesture/react";

const url = `${process.env.PUBLIC_URL}/models/computer/scene.gltf`;

export default function Model({ handlerOnClick, ...props }) {
  const [scaleRatio, setScaleRatio] = useState(0.3);
  const [position, setPosition] = useState([0, 0, 0]);
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;
  const group = useRef();
  const { nodes, materials } = useGLTF(url);

  const bindDrag = useDrag(
    ({ offset: [x, y] }) => {
      const [, , z] = position;
      setPosition([x / aspect, -y / aspect, z]);
    },
    { pointerEvents: true }
  );

  const bindhover = useHover(
    ({ hovering }) => {
      setScaleRatio(hovering ? 0.4 : 0.3);
    },
    {
      mouseOnly: true,
    }
  );

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          position={position}
          {...bindDrag()}
          {...bindhover()}
          geometry={nodes.Object_2.geometry}
          material={materials.palette}
          scale={[scaleRatio, scaleRatio, scaleRatio]}
          onClick={handlerOnClick}
        />
      </group>
    </group>
  );
}

useGLTF.preload(url);
