import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import Asset from "../components/Asset";
import TextJumbo from "../components/TextJumbo";

const HomeSegment = () => {
  const constDirection = 8.25;
  return (
    <Canvas camera={{ position: [1, 0, 9] }}>
      <ambientLight intensity={2} />
      <pointLight position={[40, 40, 40]} />
      <directionalLight
        position={[-8, 12, 8]}
        shadow-camera-left={constDirection * -1}
        shadow-camera-bottom={constDirection * -1}
        shadow-camera-right={constDirection}
        shadow-camera-top={constDirection}
        shadow-camera-near={0.1}
        shadow-camera-far={1500}
        castShadow
      />
      <mesh position={[0, -3, -10]}>
        <circleBufferGeometry attach="geometry" args={[8, 64]} />
        <meshBasicMaterial attach="material" color="#936FF9" />
      </mesh>

      <Suspense fallback={null}>
        <TextJumbo />
        <Asset url={`${process.env.PUBLIC_URL}/models/scene.gltf`} />
      </Suspense>
    </Canvas>
  );
};

export default HomeSegment;
