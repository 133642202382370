import React from "react";
import { Switch, Route } from "react-router-dom";

import HomeSegment from "../Fragments/Home";
import AboutSegment from "../Fragments/About";

export const IndexRouter = () => {
  return (
    <Switch>
      <Route path="/about">
        <AboutSegment />
      </Route>
      <Route path="/">
        <HomeSegment />
      </Route>
    </Switch>
  );
};
