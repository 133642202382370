import React, { useRef, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useLoader, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import _isMobile from "../utils/mobile";

export default function Asset({ url }) {
  const gltf = useLoader(GLTFLoader, url);
  const ref = useRef();

  const primitiveProps = {
    ref,
    object: gltf.scene,
    materials: gltf.materials,
    position: _isMobile() ? [0, -5.5, 0] : [0, -1.5, 0],
    rotation: [0, 0, 0],
    scale: [1.4, 1.4, 1.4],
    castShadow: true,
  };
  const [mixer] = useState(() => new THREE.AnimationMixer());

  useEffect(() => {
    mixer.clipAction(gltf.animations[0], ref.current).play();
  }, [mixer]);

  useFrame((state, delta) => {
    ref.current.rotation.y +=
      Math.sin((delta * 0.7) / 2) * Math.cos((delta * 0.7) / 2) * 1.5;
    mixer.update(delta);
  });

  return (
    <>
      <ambientLight castShadow={true} />
      <spotLight intensity={0.5} position={[300, 300, 4000]} />
      <primitive {...primitiveProps} material={gltf.materials.Stone} />
      <directionalLight color="#0000" intensity={1} position={[-1, 4, 4]} />
    </>
  );
}
