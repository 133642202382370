import React from "react";
import { Header } from "./header/style";

import { MenuHeader } from "./menu-header";

export const HeaderLV = () => {
  return (
    <Header>
      <MenuHeader></MenuHeader>
    </Header>
  );
};
