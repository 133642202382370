import styled, { keyframes } from "styled-components";
import { Layout } from "antd";
import { fadeInUp } from "react-animations";
const { Header: HeaderStyled } = Layout;

export const Li = styled.li`
  list-style-type: none;
`;

export const Header = styled(HeaderStyled)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  background: none;
`;

const fadeInUpAnimation = keyframes`${fadeInUp}`;

export const Menu = styled.div`
  color: white;
  animation: 1s ${fadeInUpAnimation};
`;
