import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import ComputerModel from "../components/models/Computer";

const AboutSegment = () => {
  const [isOpenCV, setIsOpenCV] = useState(false);
  const handlerOpenCV = () => {
    setIsOpenCV(!isOpenCV);
  };
  const constDirection = 8.25;
  return (
    <Canvas camera={{ position: [2, 2, 6] }}>
      <ambientLight intensity={2} />
      <pointLight position={[40, 40, 40]} />
      <directionalLight
        position={[-8, 12, 8]}
        shadow-camera-left={constDirection * -1}
        shadow-camera-bottom={constDirection * -1}
        shadow-camera-right={constDirection}
        shadow-camera-top={constDirection}
        shadow-camera-near={0.1}
        shadow-camera-far={1500}
        castShadow
      />

      <Suspense fallback={null}>
        <ComputerModel className="icon" handlerOnClick={handlerOpenCV} />
      </Suspense>
    </Canvas>
  );
};

export default AboutSegment;
