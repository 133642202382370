import React from "react";
import "./App.css";
import { Layout } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { HeaderLV } from "./components/header";

import { IndexRouter } from "./pages";

const { Content, Footer } = Layout;

export const App = () => {
  return (
    <Layout style={{ background: "#0F4C81" }}>
      <Router>
        <HeaderLV style={{ marginTop: "20px", paddingTop: "50px" }}></HeaderLV>;
        <Content style={{ height: "100vh" }}>
          <IndexRouter />
        </Content>
      </Router>
      <Footer style={{ textAlign: "center" }}>
        ___this webpage was created with
        <span role="img" aria-label="love">
          ♥️
        </span>
        ___
      </Footer>
    </Layout>
  );
};

export default App;
